<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <section class="col-4">
        <label>Search</label>
        <b-input-group>
          <b-form-input
            v-model="filters.search"
            class="d-inline-block"
            placeholder="Search by owner identifier..."
            @keydown.enter="getMoreLikes(1)"
          />
          <b-input-group-append>
            <b-button @click="getMoreLikes(1)">Search</b-button>
          </b-input-group-append>
        </b-input-group>
      </section>

      <section class="col-2 d-flex justify-content-end align-items-center">
        <b-button @click="clearFilters">Clear filters</b-button>
      </section>
    </div>

    <section v-if="!loading">
      <b-table
        :current-page="filters.page"
        :fields="headers"
        :items="likes"
        :per-page="filters.page_size"
        class="data-table"
        hover
      >
        <template #cell(owner)="data">
          <router-link
            v-if="$can('Read', 'Owner')"
            :to="`/owners/owner/${data.item.owner.uid}`"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.owner.identifier }}
          </router-link>
          <span v-else>{{ data.item.owner.uid }}</span>
        </template>

        <template #cell(date)="data">
          <span>
            {{ data.item.date | formatDateTime }}
          </span>
        </template>
      </b-table>

      <b-row>
        <b-col v-if="total_count > filters.page_size" class="my-1" md="6">
          <b-pagination
            v-model="currentPage"
            :per-page="filters.page_size"
            :total-rows="total_count"
            class="my-0 mx-1"
            @change="getMoreLikes"
          />
        </b-col>
        <p v-if="total_count === 0" class="col-6 ml-2 my-1">No records</p>
      </b-row>
    </section>
  </div>
</template>

<script>
import TheHubService from "@/services/TheHubService";

export default {
  name: "LikesTable",
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          key: "owner",
          label: "Device Owner",
        },
        {
          key: "date",
          label: "Date",
        },
      ],
      filters: {
        page: 0,
        page_size: 15,
        search: "",
      },
      currentPage: 1,
      total_count: 0,
      likes: [],
      loading: false,
    };
  },
  mounted() {
    this.getLikes();
  },
  methods: {
    async getLikes() {
      if (this.loading) return;

      this.loading = true;
      try {
        let { data } = await TheHubService.getArticleLikes(
          this.$props.article.id,
          this.filters
        );
        this.likes = data.data;

        this.filters.page = data.meta.page;
        this.filters.page_size = data.meta.page_size;
        this.total_count = data.meta.total_count;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get likes, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }
        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    getMoreLikes(page) {
      this.filters.page = page - 1;
      this.currentPage = page;
      this.getLikes();
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 15,
        search: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
