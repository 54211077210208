<template>
  <div>
    <b-card v-if="$can('Read', 'TheHub.Comments')" title="Comments">
      <comments-table :article="article" />
    </b-card>

    <b-card v-if="$can('Read', 'TheHub.Likes')" title="Likes">
      <likes-table :article="article" />
    </b-card>
  </div>
</template>

<script>
import LikesTable from "@/views/theHub/sections/LikesTable.vue";
import CommentsTable from "@/views/theHub/sections/CommentsTable.vue";

export default {
  name: "ArticleActivity",
  components: { LikesTable, CommentsTable },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
