<template>
  <div class="network-page">
    <section v-if="!loading">
      <div v-if="article" class="row">
        <div class="col-12">
          <b-card title="TheHub - Article Details">
            This page contains details on your article published on TheHub.
          </b-card>
        </div>
        <b-tabs
          v-if="$can('Read', 'TheHub.Likes') || $can('Read', 'TheHub.Comments')"
          class="col-12"
          content-class="my-3"
        >
          <b-tab active title="Details">
            <article-editor
              v-if="$can('Update', 'TheHub.Articles')"
              :article="article"
            />
            <b-card v-else>
              <article-preview :article="article" />
            </b-card>
          </b-tab>
          <b-tab title="Activity">
            <article-activity :article="article" />
          </b-tab>
        </b-tabs>

        <div v-else class="col-12">
          <article-editor
            v-if="$can('Update', 'TheHub.Articles')"
            :article="article"
          />
          <b-card v-else>
            <article-preview :article="article" />
          </b-card>
        </div>
      </div>

      <!-- Loading -->
      <section v-else>
        <b-spinner label="Loading TheHub Article" variant="primary" />
      </section>
    </section>
  </div>
</template>

<script>
import TheHubService from "@/services/TheHubService";
import ArticleEditor from "@/views/theHub/sections/ArticleEditor.vue";
import ArticleActivity from "@/views/theHub/sections/ArticleActivity.vue";
import ArticlePreview from "@/views/theHub/sections/ArticlePreview.vue";

export default {
  name: "ViewArticle",
  components: { ArticlePreview, ArticleActivity, ArticleEditor },
  data() {
    return {
      article: null,
      loading: false,
    };
  },
  mounted() {
    this.getArticle();
  },
  methods: {
    async getArticle() {
      this.loading = true;

      try {
        let res = await TheHubService.getArticle(this.$route.params.id);
        this.article = res.data;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get article, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
